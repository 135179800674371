export default [
  {
    _name: 'CSidebarNav',
    _children: [

      {
        _name: 'CSidebarNavDropdown',
        name: 'Admisión',
        route: '/admision',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Inicio',
            to: '/admision/inicio'
          },
          {
            name: 'Datos personales',
            to: '/admision/datosPersonales'
          },
          {
            name: 'Referencia Bancaria',
            to: '/admision/referenciaBancaria'
          },
          {
            name: 'Datos ceneval',
            to: '/admision/ceneval'
          },
          {
            name: 'Generar ficha',
            to: '/admision/ficha'
          }

        ]
      }

    ]
  }
]
