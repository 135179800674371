<template>
  <div class="c-app">
    <CWrapper>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
<!-- hedaer para salir -->
 <CRow>
    <CCol col="12" md="12">
      <CCard>
        <CCardHeader>
             <CCol sm="12" style="text-align: center; margin-top: 20px;">
                  <CButton  style="width: 200px; margin-left: auto; margin-right: auto" color="success" @click="salir()" block>Salir</CButton>
              </CCol>
        </CCardHeader>
      </CCard>
    </CCol>
  </CRow>

    <!-- <CHeader fixed with-subheader light>
      <CCardHeader>
             <CCol sm="12" style="text-align: center; margin-top: 20px;">
                  <CButton  style="width: 200px; margin-left: auto; margin-right: auto" color="success" @click="salir()" block>Salir</CButton>
              </CCol>
        </CCardHeader>
  </CHeader> -->

            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
     
      <TheFooter/>

    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },

  methods:{
   salir(){
                  
    
          localStorage.removeItem('id');
           localStorage.removeItem('validaDocumentos2');
              localStorage.removeItem('validaDocumentosAceptado');
           localStorage.removeItem('token');
            this.$router.push('/pages/login');

    }
    }



}




</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
